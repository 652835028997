import { Controller } from '@hotwired/stimulus'
import Uppy from '@uppy/core'
import Dashboard from '@uppy/dashboard'
import Tus from '@uppy/tus'

export default class extends Controller {
  static targets = ['input', 'dashboard']

  connect() {
    this.uppy = new Uppy({
      debug: true,
      autoProceed: true,
      doneButtonHandler: null,
      restrictions: {
        maxFileSize: 2 * 1024 * 1024 * 1024, // 2GB
        maxNumberOfFiles: 1,
        allowedFileTypes: ['video/*'],
      },
    })
      .use(Dashboard, {
        target: this.dashboardTarget,
        inline: true,
        height: 300,
        width: '100%',
        proudlyDisplayPoweredByUppy: false,
        showProgressDetails: true,
        doneButtonHandler: () => null,
        theme: 'light',
        note: 'Video files only, up to 150MB',
      })
      .use(Tus, {
        endpoint: '/api/cloudflare/direct_uploads',
        resume: true,
        removeFingerprintOnSuccess: true,
      })

    this.uppy.on('upload-success', (file, response) => {
      // Extract video ID from Cloudflare upload URL
      const videoId = response.uploadURL.split('/').pop().split('?')[0]
      this.inputTarget.value = videoId

      // Trigger change event so form knows value was updated
      this.inputTarget.dispatchEvent(new Event('change', { bubbles: true }))
    })
  }

  disconnect() {
    this.uppy?.close()
  }
}
