import { Controller } from '@hotwired/stimulus'
import { get, put } from '@rails/request.js'

export default class NotificationsController extends Controller {
  static targets = ['list', 'pagination']

  async read() {
    await put('/api/notifications', {
      body: {
        read_at: new Date().toISOString(),
      },
      contentType: 'application/json',
      responseKind: 'turbo-stream',
    })
  }

  async reload() {
    document.querySelectorAll('turbo-frame#notifications').forEach((frame) => {
      frame.reload()
    })
  }

  async loadMore(event) {
    event.preventDefault()
    const nextPageUrl =
      this.paginationTarget.querySelector('a[rel="next"]').href
    const response = await get(nextPageUrl, { responseKind: 'turbo-stream' })
    const html = await response.text
    this.listTarget.insertAdjacentHTML('beforeend', html)
    this.paginationTarget.innerHTML = html.match(
      /<nav class="pagination">([\s\S]*?)<\/nav>/
    )[1]
  }
}
