import { Controller } from '@hotwired/stimulus'

export default class FieldToggleController extends Controller {
  static targets = ['item']

  connect() {
    this.update()
  }

  update = () => {
    const displayAs = this.data.get('displayAs') || 'radio'
    let visible = []
    let hidden = []

    if (displayAs === 'select') {
      const select = this.element.querySelector('select')
      const selectedOption = select.options[select.selectedIndex]
      try {
        visible = JSON.parse(selectedOption.dataset.visible || '[]')
        hidden = JSON.parse(selectedOption.dataset.hidden || '[]')
      } catch (error) {
        console.error('Error parsing data attributes:', error)
      }
    } else if (displayAs === 'radio') {
      const checkedItems = this.itemTargets.filter((item) => item.checked)
      visible = checkedItems.reduce((acc, item) => {
        try {
          return acc.concat(JSON.parse(item.dataset.visible || '[]'))
        } catch (error) {
          console.error('Error parsing visible data:', error)
          return acc
        }
      }, [])
      hidden = checkedItems.reduce((acc, item) => {
        try {
          return acc.concat(JSON.parse(item.dataset.hidden || '[]'))
        } catch (error) {
          console.error('Error parsing hidden data:', error)
          return acc
        }
      }, [])
    }

    visible.forEach((id) => this.show(id))
    hidden.forEach((id) => this.hide(id))
  }

  show(id) {
    const element = this.form.querySelector(`#${id}_wrapper`)
    if (element) {
      element.classList.remove('hidden')
    } else {
      console.warn(`Element with ID #${id}_wrapper not found.`)
    }
  }

  hide(id) {
    const element = this.form.querySelector(`#${id}_wrapper`)
    if (element) {
      element.classList.add('hidden')
    } else {
      console.warn(`Element with ID #${id}_wrapper not found.`)
    }
  }

  get form() {
    return this.element.closest('form')
  }
}
